$mobile-min-height: 346px !default;
$mobile-max-height: 695px !default;
$tablet-min-height: 699px !default;
$tablet-max-height: 1440px !default;
$tablet-width: 768px;
$desktop-width: 1025px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (max-height: $tablet-max-height) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (min-height: $mobile-min-height) and (max-height: $mobile-max-height) and (max-width: #{$desktop-width - 1px}) and (orientation: landscape){
    @content;
  }
}

@mixin mobile-portrait {
  @media (max-width: #{$tablet-width - 1px}) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (min-height: $tablet-min-height) and (max-height: $tablet-max-height) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation: portrait) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.Mui-disabled { 
  color: #FFFFFF !important; 
  background-color: #D6D6D6 !important;
}

.mailLink {
  text-decoration: underline;
  color: #404042;
}

.MuiContainer-root {
  padding-left: 44px !important;
  padding-right: 44px !important;

  @include mobile {
    padding-left: 31px !important;
    padding-right: 31px !important;
  }
}

.chooseDeviceModal {
  z-index: 100;
  height: 100%;
  font-size: 10px;
}

.viewHolder {
  @include mobile {
    overflow-y: scroll;
  padding-bottom: 30px;
  //background-color: #384967;
  }
}

.modalCloseButton {
  margin: 15px;
  position: relative;
  left: -1566px;
  display: block;
  top: 56px;
  color: green;
}

a {
  text-decoration: none;
}

.textLinkGridBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
}

a.textLink {
  font-size: 20px;
  color: #384967;
  text-decoration: underline;
  text-transform: none;
}

.hidden {
  display: none;
}

//hide the step arrows on a number input field
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.deviceName {
  display: inline-flex;
}

.logo img {
  margin-top: 20px;
  width: 78px;
  height: auto;
  position: absolute;
  top:1px;
  right:20px;
  z-index:15;

  @include mobile {
    width: 50px;
    top:2px;
    right:10px;
  }

  @include mobile-portrait {
    width: 50px;
    top:-5px;
    right:10px;

    &--getStarted {
      width: 500px;
      top:-50px;
      right:10px;
    }
  }
}

.getStartedLogo img {
  margin-top: 20px;
  width: 78px;
  height: auto;
  position: absolute;
  top:1px;
  right:20px;
  z-index:15;

  @include mobile {
    width: 50px;
    top:2px;
    right:10px;
  }

  @include mobile-landscape {
    width: 70px;
    top:1px;
    right:15px;
  }

  @include mobile-portrait {
    width: 70px;
    top:1px;
    right:15px;
  }
}

.resultsLogo img {
  margin-top: 20px;
  width: 78px;
  height: auto;
  position: absolute;
  top:1px;
  right:20px;
  z-index:15;

  @include mobile {
    width: 50px;
    top:2px;
    right:10px;
  }

  @include mobile-portrait {
    width: 40px;
    top:-10px;
    right:10px;
  }
}

.homeBackground {
  height: 100vh;
  background-color: #FFFFFF;
  background-size: 100% 100%;
  background-position:top;
  background-repeat: no-repeat;
  @include desktop {
    //border: 5px solid red;
    background-size: auto 868px;
    background-image: url('/images/Home_background_tablet-desktop_landscape-2x.png');
    overflow-y: scroll;
    height: 868px;
  }

  @include tablet{
    // border: 5px solid orange;
    background-image: url('/images/Home_background_tablet-desktop_landscape-2x.png');
   }
  
  @include tablet-landscape{
    // border: 5px solid orange;
    background-image: url('/images/Home_background_tablet-desktop_landscape-2x.png');
   }

  @include tablet-portrait{
   // border: 5px solid orange;
    background-size: auto 100%;
    background-image: url('/images/Home_Background_Tablet_Portrait-2x.png');
  }

  @include mobile{
    
    background-size: 100% auto;
  }


  @include mobile-landscape{
    background-color: #384967;
  }
  
  @include mobile-portrait{
    background-color: #384967;
    //padding-bottom: 1500px;
  }
}

.getStartedLogoContainer {
  @include desktop {
    display: none !important;
  }
  @include tablet {
    display: none !important;
  }
  @include tablet-portrait {
    display: none !important;
  }
  @include tablet-landscape {
    display: none !important;
  }
}

.letsGetStartedContentBox {

  background-repeat: no-repeat;

    // @include tablet-landscape{
    //   background-position:top right 0;
    // background-image: url('images/Home_mobile_landscape.svg');
    // background-color: #FFFFFF;
    // }

  @include mobile-portrait{
    background-position:top right 0;
    background-image: url('images/Home_mobile_portrait.svg');
    background-color: #FFFFFF;
  }

  @include mobile-landscape{
    background-position:top right 0;
    background-image: url('images/Home_mobile_landscape.svg');
    background-color: #FFFFFF;
  }
}

.letsGetStartedButton {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 30px;

  @include tablet-portrait{
    justify-content: flex-start;
    margin-top: -25px !important;
   }

   @include mobile-portrait{
    justify-content: flex-start;
   }

   @include mobile-landscape{
    justify-content: flex-start;
   }

   @include tablet{
    padding-bottom: 30px !important;
   }
}

.letsGetStartedButtonBox {

  @include mobile-portrait{
  padding-bottom: 70px;
  background-color: #384967;
  }

  @include mobile-landscape{
    min-height: 120px;
    background-color: #384967;
  }
}

.ResultDiv {
  display: flex;
  flex-direction: row;
  border-bottom: #F1BE48 2px solid;
  padding: 20px 20px 20px 0;
  @include tablet-portrait{
    flex-direction: column;
   }

   @include mobile-portrait{
    flex-direction: column;
   }
}

.getStartedContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.modalBox {
  background-color: #384967;
  //opacity: 0.9;
  z-index: 100;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dontKnowModalContainer {
  background-color: #FFFFFF;
  border: #F1BE48 1px solid;
  margin-top: 60px;
  padding: 0 56px 72px 56px;
}

.gotoMyCochlearNextButtonContainer {
  margin-top: 56px;
  margin-bottom: 0;
}

.disclaimerLinks {
  margin: 27px 0; 
  padding: 30px 0; 
  border-bottom: 1px #F1BE48 solid;
}

.headerBox {
    padding-bottom: 0;
    color: #ffffff;
    background-color: #384967;
    margin-bottom: 167px;
    margin-top: -1px;
    display: flex;
    width: 100%;
    height: 166px;
    margin: 0 auto;
    background-image: url('images/Header_desktop_landscape.svg');
    background-repeat: no-repeat;
    background-position: right;
    //border: 1px solid red;
    // @include desktop {
    //   height: 166px;
    //   background-image: url('images/Header_desktop_landscape.svg');
    // }

    // @include tablet{
    //   height: 196px;
    //   background-image: url('images/Header_tablet_portrait.svg');
    //  //border: 1px solid green;
    // }

    @include mobile-portrait{
      height: 198px;
      background-image: url('images/Header_mobile_portrait.svg');
     //border: 1px solid purple;
    }
    
}

.finalResultsHeaderBox {
  padding-bottom: 0;
  color: #ffffff;
  background-color: #384967;
  margin-bottom: 167px;
  margin-top: -1px;
  display: flex;
  width: 100%;
  height: 268px;
  margin: 0 auto;
  background-image: url('images/results/Results_header_tablet_landscape.svg');
  background-repeat: no-repeat;
  background-position: right;
  // border: 1px solid red;
  @include mobile-portrait{
    background-image: url('images/results/Results_header_tablet_portrait.svg');
    background-size: 100% auto;
    background-position: top right 0;
  }
  z-index: -1;
}

.resultsHeaderBox {
  background-color:transparent;
  color: #FFFFFF;
  background-color: #384967;
}

.resultsHeaderIcons {
  background-color:transparent;
  color: #FFFFFF;
  top: 10;
  margin-top: -255px;
  // position: absolute;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}

.resultsBox {
  background-color:#384967;
}

.resultsBody {
  background-color:#384967;
}

.headerSummaryBox {
  background-color:#384967;
  height: 166px;
  
}

.headerTitleContainer{

 padding-top: 91px;
 padding-bottom: 60px;

}

.deviceImage {
  width:79px;
  height:79px;
}

.directionButtonsWrapper {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 40px;
  padding-bottom: 40px;

  @include mobile{
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 30px;
  }
}

.greySummaryBox {
  padding: 39px;
  background: rgba(214,214,214, 0.25) 0% 0% no-repeat padding-box;
  @include mobile {
    padding: 20px 1px;
  }
}

.textColumn {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  justify-content: center;
  align-content: center;
}

.dollarSignAdornment {
  color: green;
  font-size: 85px !important;
}

.AccordionBox {
  padding: 30px;
  background-color: white;
}

.resultsImageEstimateBox {
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #384967;
  padding: 13px 29px 32px 29px;
}

.underlined {
  text-decoration: underline;
}

.disclaimerContainer {
  padding-top: 25px;
}

h5.currentDeviceName {
    font: normal normal bold 25px/20px 'CircularXXBook';

  @include mobile {
    font: normal normal bold 23px/20px 'CircularXXBook';
  }
}

h5.optionButtonText {
  font: normal normal bold 25px/20px 'CircularXXBook';

@include mobile {
  font: normal normal bold 21px/25px 'CircularXXBook';
}
}

h5.currentDeviceSoundProcessors {
  margin-top: 3px;
  font: normal normal bold 16px/20px 'CircularXXLight';
}

h5.superScript, .superScript {
  vertical-align: super;
  margin-top: -5px;
  font: normal normal bold 16px/20px 'CircularXXBook';

  @include mobile {
    font: normal normal bold 14px/20px 'CircularXXBook';
  }
}

sup {
  font-size:60%;
}

h4.haveOwnedFor {
  color: #404042;
  font: normal normal bold 20px/22px 'CircularXXBook';

  @include mobile {
    font: normal normal bold 16px/22px 'CircularXXBook';
  }
}

.moreOrLess {
  color: #404042;
  font: normal normal bold 20px/22px 'CircularXXBold';
  @include mobile {
    font: normal normal bold 16px/22px 'CircularXXBold';
  }
}

h5.chooseDeviceThatsOK {
  font: normal normal bold 16px/20px 'CircularXXBook';
  color: #404042;
  margin-top: 5px;

  @include mobile {
    display: none;
  }
}

h3.boldResultWithImage {
  font: normal normal bold 32px/35px 'CircularXXBold';
  color: #384967;
  text-align: center;
}

h3.boldResult {
  font: normal normal bold 26px/30px 'CircularXXBold';
  color: #384967;
  text-align: center;
}

h4.modalText {
  margin: 30px 0 0 0;
  font: normal normal bold 25px/30px 'CircularXXMedium';
  color: #404042;

  a {
    text-decoration: underline;
  }

  a:link, a:visited, a:active {
    color: #404042;
  }

  a:hover {
    color: #F1BE48;
  }
}

h4.resultsTitle {
  margin: 30px 0 0 0;
  font: normal normal bold 25px/22px 'CircularXXMedium';
  color: #F1BE48;
}

h6.resultsHeaderExplanation {
  font: normal normal bold 15px/22px 'CircularXXBook';
  color: #FFFFFF;
}

h6.resultsExplanation {
  font: normal normal bold 15px/22px 'CircularXXBook';
  color: #404042;
}

h4.resultsSectionTitle {
  font: normal normal bold 25px/22px 'CircularXXBook';
  margin-bottom: 10px;
  color: #384967;
}

h4.outOfPocketExplanation {
  font: normal normal bold 25px/22px 'CircularXXMedium';
  color: #F1BE48;
  margin: 30px 0 0 0;
}

h4.selfPayQuestion {
  font: normal normal bold 25px/30px 'CircularXXMedium';
  color: #384967;
  font-weight: bold;
}

h5.selfPayExplanation {
  font: normal normal bold 15px/22px 'CircularXXBook';
}

h5.selfPayOptionType {
  font: normal normal bold 25px/30px 'CircularXXBook';
  margin-bottom: 25px;
  color: #404042;
  text-align: center;
}

h4.book25Yellow {
  font: normal normal bold 25px/22px 'CircularXXMedium';
  color: #F1BE48;
}

h5.savedPropertyPrompt {
  font: normal normal bold 18px/22px 'CircularXXBook';
  color: #384967;
}

h5.savedProperty {
  font: normal normal bold 20px/22px 'CircularXXMedium';
  color: #384967;
}

.savedPropertyGrid {
  padding: 20px 0;

  h5 {
    padding: 20px 0 0 0;
  }
}

.savedPropertyGridRight {
  padding: 1px 0;

  h5 {
    padding: 20px 0 0 0;
  }
}

h5.savedPropertyDisclaimer {
  font: normal normal bold 14px/16px 'CircularXXLightItalic';
  color: #404042;
  margin-top: 20px;
}

.savedPropertyDisclaimerWrapper {
  padding-top: none !important;
}

subtitle2.disclaimer {
  font: normal normal bold 12px/14px 'CircularXXBook';
}

h4.headerTitle {
  font: normal normal bold 25px/22px 'CircularXXMedium';

  @include mobile {
    font: normal normal bold 23px/22px 'CircularXXMedium';
  }

  @include mobile-portrait {
    font: normal normal bold 20px/22px 'CircularXXMedium';
  }
}

h6.headerSubTitle {
  font: normal normal bold 15px/22px 'CircularXXBook';
  a {
    color: #FFFFFF;

    &:hover{
      text-decoration: underline;
    }
  }
}

h6.insuranceGroup {
  font: normal normal bold 20px/25px 'CircularXXBook';
}

h4.mainQuestion {
  font: normal normal bold 25px/30px 'CircularXXMedium';
  margin-bottom: 20px;
  margin-top: 67px;
  a {
    color: #384967;
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
    &:active{
      text-decoration: underline;
    }
    &:visited{
      text-decoration: underline;
    }
  }

  @include mobile {
    font: normal normal bold 23px/25px 'CircularXXMedium';
  }
}

.getStartedTitleContainer {
  display: flex;
  justify-content: flex-end;

  @include mobile{
    justify-content: flex-start;
   }

   @include tablet-portrait{
    justify-content: flex-start;
   }
}

h3.getStartedTitle {
  font: normal normal bold 35px/40px 'CircularXXMedium';
  color: #404042;
  @include desktop {
    //border: 5px solid red;
    // margin-top: 42px;
    margin-top: 120px;
    margin-bottom: 40px;
  }

  @include tablet{
    //border: 5px solid purple;
    font: normal normal bold 25px/30px 'CircularXXMedium';
    margin-top: 42px;
    text-align: left;
   }

  @include tablet-landscape{
    //border: 5px solid purple;
    font: normal normal bold 25px/30px 'CircularXXMedium';
    margin-top: 42px;
   }

  @include tablet-portrait{
   //border: 5px solid orange;
   font: normal normal bold 25px/30px 'CircularXXMedium';
   margin-top: 112px;
   text-align: left;
  }

  @include mobile{
   // border: 5px solid green;
   font: normal normal bold 25px/32px 'CircularXXMedium';
   margin-top: 122px;
   text-align: left;
  }

  @include mobile-landscape{
    // border: 5px solid green;
    font: normal normal bold 25px/32px 'CircularXXMedium';
    margin-top: 122px;
    text-align: left;
   }
}

h5.getStartedSubTitle{
  font: normal normal bold 18px/22px 'CircularXXBook';
  color: #404042;
  margin-bottom: 40px;

  @include tablet-portrait{
    text-align: left;
   }

   @include tablet {
    text-align: left;
   }

  @include mobile{
    text-align: left;
   }

   @include mobile-landscape{
    text-align: left;
   }
}

h5.getStartedTime{
  margin-top: 10px;
  font: normal normal bold 16px/20px 'CircularXXLightItalic';
  color: #F1BE48;

  @include desktop{
    color: #384967;
    margin-top: -30px;
  }

  @include tablet-portrait{
    margin-top: 100px;
   }

   @include tablet {
    color: #384967;
    margin-top: 1px;
   }

  @include mobile{
    margin-top: 1px;
   }

   @include mobile-landscape{
    
   }
}



h4.emailFormLabel {
  font: normal normal bold 25px/22px 'CircularXXBook';
  margin-top: 25px;
  margin-bottom: 15px;
}

.resultsItem {
  display: flex;
  align-items: flex-start;
  height: 50px;
  margin-right: 20px;
}

.leftResultsBox {
  margin-bottom: 10px !important;
  @include desktop {
    margin-bottom: 0 !important;
    padding-right: 20px;
  }
}

.rightResultsBox {
  @include desktop {
    padding-left: 20px;
  }
}

.resultsItemLarge {
  // height: 50px;
  margin-bottom: 40px;
  margin-right: 20px;
  @include desktop {
    height: 90px;
  }
}

.resultsLinksSectionContainer {
  padding-top: 40px;
}

h3.resultOutOfPocketDisclaimer {
  margin-top: 16px;
  font: normal normal bold 15px/22px 'CircularXXBook';
  color: #F1BE48;
}

h4.planForTheFutureTitle {
  font: normal normal bold 25px/22px 'CircularXXMedium';
  color: #F1BE48;
}

h5.planForTheFutureText {
  font: normal normal bold 25px/30px 'CircularXXBook';
  color: #F1BE48;
}

h6.hitSend {
  font: normal normal bold 15px/20px 'CircularXXBook';
  color: #404042;
}

.disclaimerSection {
  padding-bottom: 30px;
}


/* Fade */
.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.resultsButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.iconButtonImage{
  margin-bottom: 6px;
}

.doneButtonsContainer {
  margin-top: 88px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  //text-align: right;
  justify-content: flex-end;
}

.doneButtonsGridContainer {
  padding-left: 25px;
}

h4.emailError {
  font: normal normal bold 18px/16px 'CircularXXMedium';
  color: #CC0000;
}
