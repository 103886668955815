#inspector {
    touch-action: none;
}

.inspectorBody {
    position: absolute;
    z-index: 9999;
    width: 500px;
    left: 10px;
    top: 10px;
    height: 90vh;
    border: 1px solid #333333;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
}

.minimizedInspector {
    position: absolute;
    z-index: 9999;
    width: 100px;
    right: 90px;
    top: 100px;
    border: 1px solid #333333;
    background-color: #8775a0;
    border-radius: 8px;
    opacity: 0.6;
}

.inspectorTitle {
    align-self: center;
}

.inspectorHeader {
    padding: 10px;
    cursor: move;
    z-index: 10;
    background-color: #8775a0;
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.inspectorInfoContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #FFFFFF;
    touch-action: none;
}

.inspectorProps {
    padding: 5px;
    min-height: 250px;
    /* touch-action: none; */
    overflow-y: scroll;
}

.inspectorPropertyAlternatorField {
    border-top: 1px solid #333333;
    /* height: 20%; */
    overflow: scroll;
}

.inspectorConsole {
    background: #FFFFFF;
    border-top: 1px solid #333333;
    overflow-y: scroll;
    padding: 0 5px;
    border-top: 2px solid black;
    /* touch-action: none; */
}

.inspector ul {
    list-style: none;
    margin-left: 0;
    padding-inline-start: 0;
}

.inspectorConsole ul {
    list-style: none;
    margin-left: 0;
    padding-inline-start: 10px;
}

pre {
    white-space: pre-wrap;
}

.inspectorButtonPanel {
    border-top: 1px solid #000000;
    padding: 6px 2px;
    display: flex;
    justify-content: space-between;
}

.inspectorClearConsoleButton, .inspectorClearConsoleButton:focus {
    background-color: rgba(62, 72, 80, 0.78);
    border: none;
    color: white;
    padding: 3px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.inspectorClearConsoleButton:active {
    background-color: #FFFFFF;
    color: #8775a0;
}

.inspectorMakeClickableButton, .inspectorMakeClickableButton:focus {
    background-color: #8775a0;
    color: #FFFFFF;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.inspectorMakeClickableButton:active {
    background-color: #FFFFFF;
    color: #8775a0;
}

.inspectorFindComponentsButton, .inspectorFindComponentsButton:focus {
    align-self: center;
    background-color: #8775a0;
    color: #FFFFFF;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.inspectorFindComponentsButton:active {
    background-color: #FFFFFF;
    color: #8775a0;
}

.hidden {
    display: none;
}

.propertyInfoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
}

.propertyInfoItemWrapperText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
    align-items: center;
    width: 100%;
}

.propertyInfoItemWrapperButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
    align-items: center;
}

.propertyInfoItem {
    padding: 0 10px;
}

.propertyInfoItemClassNameDiv {
    background-color: #cfc4d5;
    padding: 20px 0;
    border-top: 2px dotted #000000;
    border-bottom: 2px dotted #000000;
}

.propertyInfoItemName {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.propertyInfoInput {
    width: 100% !important;
    min-width: 230px;
}

.propertyInfoIncrementButton, .propertyInfoIncrementButton:focus {
    width: 30px;
    height: 30px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8775a0;
    color: #FFFFFF;
    font-weight: bold;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none;
}

.propertyInfoIncrementButton:active {
    background-color: #FFFFFF;
    color: #8775a0;
}

.minimizeButton, .minimizeButton:focus {
    width: 30px;
    height: 30px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8775a0;
    color: #FFFFFF;
    font-weight: bold;
    border: 1px solid white;
    /* position: absolute;
    left: 10px; */
}

.minimizeButton:active, .minimizeButton:hover {
    background-color: #FFFFFF;
    color: #8775a0;
}

.propertiesOrConsole, .propertiesOrConsole:focus {
    height: 30px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8775a0;
    color: #FFFFFF;
    font-weight: bold;
    border: 1px solid white;
}

.propertiesOrConsole:active, .propertiesOrConsole:hover {
    background-color: #FFFFFF;
    color: #8775a0;
}

.maximizeButton, .maximizeButton:focus {
    width: 30px;
    height: 30px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8775a0;
    color: #FFFFFF;
    font-weight: bold;
    border: 1px solid white;
}

.maximizeButton:active, .maximizeButton:hover {
    background-color: #FFFFFF;
    color: #8775a0;
}

/* iPads (landscape) ----------- */

@media only screen and (min-width: 30px) and (max-width: 896px) and (max-height: 480px) {
    .inspectorBody {
        width: 300px;
        height: 75vh9;
    }

    .propertyInfoInput {
        width: 35% !important;
        min-width: 60px;
    }

    .propertyInfoItem {
        padding: 0 6px;
    }

    .propertyInfoItemClassNameDiv {
        padding: 5px 0;
    }

    .propertyInfoItemName {
        font-size: 16px;
        font-weight: normal;
    }

    .propertyInfoItemWrapperText {
        padding: 1px 0;
    }

    .propertiesOrConsole, .propertiesOrConsole:focus {
        font-size: 16px;
        font-weight: normal;
    }

    .minimizeButton, .minimizeButton:focus {
        width: 30px;
        height: 30px;
        font-size: 16px;
        font-weight: normal;
    }

    .inspectorMakeClickableButton, .inspectorMakeClickableButton:focus {
        padding: 3px 5px;
        font-size: 14px;
    }

    .inspectorFindComponentsButton, .inspectorFindComponentsButton:focus {
        padding: 3px 5px;
        font-size: 14px;
    }

    .inspectorProps {
        padding: 0;
    }

    .inspectorHeader {
        padding: 2px;
        font-size: 16px;
    }
}